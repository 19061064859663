import { BaseProps } from "@interfaces/BaseProps";
import { INavBtn } from "../Navbar";
import Button from "@ds/Button/Button";
import Clickable from "src/components/Clickable/Clickable";

const NavBtn = (btn: INavBtn & BaseProps) => {
  return (
    <Button as="button" onClick={btn.action} disabled={btn.isDisabled} className={btn.className}>
      <Clickable coloring={btn.coloring} size="small" variant={btn.variant} isDisabled={btn.isDisabled}>
        {btn.content}
      </Clickable>
    </Button>
  );
};

export default NavBtn;
