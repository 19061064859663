import { isDesktop } from "react-device-detect";

import Container from "src/components/Container/Container";
import Stack from "@ds/Stack/Stack";
import Button from "@ds/Button/Button";
import GameCard from "src/components/GameCard/GameCard";
import Group from "@ds/Group/Group";
import Carousel from "src/components/Carousel/Carousel";

import { GAMES_SECTION_ID_SELECTOR, games } from "@constants/games";

import useIsMobile from "@hooks/useIsMobile";

import { HOW_TO_PLAY_ON_DESKTOP, HOW_TO_PLAY_ON_MOBILE } from "@constants/constants";

import { openNewTab } from "@utils/utils";
import { GAMES_URLS } from "src/config";

import "./Games.scss";

const Games = () => {
  const isMobile = useIsMobile("md");

  const gamesMap = Object.entries(games).map(([key, value], id) => {
    const url = GAMES_URLS[key as any] || "";
    const isDisabled = !url;

    return (
      <Button
        as={"button"}
        onClick={() => {
          isDisabled ? () => {} : openNewTab(url);
        }}
        key={id}
      >
        <GameCard
          {...value}
          btnText={isDisabled ? "available soon.." : "play now!"}
          isDisabled={isDisabled}
          isMobile={isMobile}
        />
      </Button>
    );
  });

  return (
    <Container className="games" maxWidth="100%" withPadding id={GAMES_SECTION_ID_SELECTOR}>
      <Container className="pt-xs-10">
        <Stack fullWidth justifyContent="flex-start" alignItems="center" rowGap={isMobile ? 16 : 40}>
          {isMobile && <img src="/images/bg/ring-blue-medium.png" className="ring" loading="lazy" />}
          <Stack fullWidth alignItems="center" rowGap={8}>
            <h2 className="title w-xs-100 t-xs-l t-md-c">Available games 🔥</h2>
            <p className="b3 op-06">Each game is randomized, and your victory depends on luck and strategy.</p>
          </Stack>
          <Group justifyContent={isMobile ? "flex-start" : "center"} alignItems="center" fullWidth>
            <Button
              as="a"
              href={HOW_TO_PLAY_ON_MOBILE}
              target="_blank"
              style={{ display: isDesktop ? "none" : "block" }}
              className="how-to-play-link"
            >
              <Group colGap={8} justifyContent="center" alignItems="center" fullSize>
                <img src="/images/icons/mobile.svg" />
                <p className="butt-3">How to play on mobile</p>
                <img src="/images/icons/yt.svg" />
              </Group>
            </Button>
            <Button
              as="a"
              href={HOW_TO_PLAY_ON_DESKTOP}
              target="_blank"
              style={{ display: !isDesktop ? "none" : "block" }}
              className="how-to-play-link"
            >
              <Group colGap={8} justifyContent="center" alignItems="center" fullSize>
                <img src="/images/icons/desktop.svg" />
                <p className="butt-3">How to play on desktop</p>
                <img src="/images/icons/yt.svg" />
              </Group>
            </Button>
          </Group>
          <Group
            colGap={20}
            rowGap={20}
            justifyContent="center"
            alignItems="flex-start"
            className="grid w-xs-100 games-desktop relative"
          >
            <img
              src="/images/bg/rings-blue-green-big.png"
              className="rings"
              loading="lazy"
              width={1920}
              height={1920}
            />
            {gamesMap}
            <div className="bottom-gradient" />
            <Group colGap={16} justifyContent="center" alignItems="center" fullWidth className="more-games">
              <img src="/images/graphics/pacman.svg" />
              <h3 className="h3-mobile text-gradient-gold-top-down">More games available in September 2024</h3>
            </Group>
          </Group>
          <Carousel className="games-mobile">{gamesMap}</Carousel>
        </Stack>
      </Container>
    </Container>
  );
};

export default Games;
