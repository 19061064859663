export const openNewTab = (url: string) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.target = "_blank";
  anchor.rel = "noreferrer noopener nofollow";
  document.body.appendChild(anchor);
  anchor.click();
};

export const formatWalletAddress = (address: string) => {
  if (!address) return "";
  return `${address.substring(0, 6)}...${address.substring(address.length - 4, address.length)}`;
};

export const formatStringNumber = (numberStr: string): string => {
  const number = parseFloat(numberStr);
  return number.toLocaleString("en", {
    minimumFractionDigits: 2, // Minimum number of fractional digits
    maximumFractionDigits: 2 // Maximum number of fractional digits
  });
};

export const cutString = (text: string, charsToLeftAmount: number) => {
  return text.slice(0, charsToLeftAmount);
};

export const copyToClipboard = (thing: any) => {
  navigator.clipboard.writeText(thing);
};

export const scrollToView = (idSelector: string) => {
  const tag = document.getElementById(idSelector);

  if (!tag) return;

  tag.scrollIntoView(true);
};
