import { ReactNode } from "react";
import Container from "src/components/Container/Container";

import { BtnVariant } from "src/components/Clickable/Clickable";
import { useUIControllerCtx } from "../../contexts/UIController";
import useIsMobile from "@hooks/useIsMobile";

import Group from "@ds/Group/Group";
import Button from "@ds/Button/Button";
import { Coloring } from "@interfaces/Coloring";

import NavBtn from "./components/NavBtn";
import MobileMenu from "./components/MobileMenu";

import { affiliationBtn, buyTokenBtn, stakingBtn } from "./constants/buttons";

import "./Navbar.scss";

export interface INavBtn {
  isDisabled: boolean;
  content: ReactNode;
  action(): void;
  coloring: Coloring;
  variant: BtnVariant;
}

const desktopMenuButtonsSet = [stakingBtn, affiliationBtn, buyTokenBtn].map((btn, id) => <NavBtn {...btn} key={id} />);

const Navbar = () => {
  const { mobileMenu } = useUIControllerCtx();
  const isMobile = useIsMobile("lg");

  return (
    <>
      <Container maxWidth="100%" className="navbar">
        <Container maxWidth="100%" className="static">
          <Container className="relative h-xs-100">
            <img src="/images/bg/top-splash.svg" className="top-splash" />
          </Container>
        </Container>
        <Container className="fixed" maxWidth="100%" withPadding>
          <Container className="inner">
            <Group fullSize justifyContent="space-between" alignItems="center">
              <img src={`/images/logos/logo-${isMobile ? "mobile" : "desktop"}.png`} />
              <Group colGap={8} className={`${isMobile ? "d-n" : "d-f"}`}>
                {desktopMenuButtonsSet}
              </Group>
              <Button as="button" onClick={mobileMenu.open} className={`${isMobile ? "d-f" : "d-n"}`}>
                <img src="/images/icons/hamburger-white-48.svg" />
              </Button>
            </Group>
          </Container>
        </Container>
      </Container>
      <MobileMenu />
    </>
  );
};

export default Navbar;
