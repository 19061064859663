import Container from "src/components/Container/Container";
import Stack from "@ds/Stack/Stack";
import Group from "@ds/Group/Group";
import Button from "@ds/Button/Button";
import { GITBOOK_LIGHTPAPER } from "@constants/constants";

import "./LightPaper.scss";

const LightPaper = () => {
  return (
    <Container className="light-paper" maxWidth="100%" withPadding>
      <Container className="pt-xs-15 pt-md-20">
        <Button as="a" href={GITBOOK_LIGHTPAPER} className="disabled">
          <Stack fullWidth alignItems="center">
            <img src="/images/graphics/github-circle-96.png" className="mb-xs-1" />
            <h5 className="mb-xs-2 t-xs-c">Check our LightPaper - soon</h5>
            <Group className="lightpaper-btn op-05" justifyContent="center" alignItems="center" colGap={4}>
              <p className="butt-3">Show me lightpaper</p>
              <img src="/images/icons/arrow-right-white.svg" />
            </Group>
          </Stack>
        </Button>
      </Container>
    </Container>
  );
};

export default LightPaper;
