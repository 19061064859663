import { useState } from "react";

export interface MobileMenuReturn {
  status: Status;
  open(): void;
  close(): void;
}

type Status = "show" | "hide" | "";

const useMobileMenu = (): MobileMenuReturn => {
  const [status, setStatus] = useState<Status>("");

  const open = () => setStatus("show");
  const close = () => setStatus("hide");

  return { status, open, close };
};

export default useMobileMenu;
