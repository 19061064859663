import React from "react";
// import "./Corner.scss";

interface Props {
  top?: boolean;
  right?: boolean;
  bottom?: boolean;
  left?: boolean;
  children: React.ReactNode;
}

const Corner = ({ children, left = false, bottom = false, right = false, top = false }: Props) => {
  return (
    <div
      style={{
        position: "absolute",
        top: top ? "0px" : "unset",
        right: right ? "0px" : "unset",
        bottom: bottom ? "0px" : "unset",
        left: left ? "0px" : "unset"
      }}
    >
      {children}
    </div>
  );
};

export default Corner;
