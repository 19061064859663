import { TChildren } from "@interfaces/IChildren";
import { BaseProps } from "@interfaces/BaseProps";

import "./ShadowBox.scss";

interface Props extends BaseProps {
  children: TChildren;
}

const ShadowBox = ({ children, ...rest }: Props) => {
  return (
    <div {...rest} className={`shadow-box ${rest.className}`}>
      {children}
    </div>
  );
};

export default ShadowBox;
