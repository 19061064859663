import mainEn from "@locales/en/main.json";

import mainPl from "@locales/pl/main.json";

export const resources = {
  en: {
    main: mainEn,
  },
  pl: {
    main: mainPl,
  },
} as const;
