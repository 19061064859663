import Container from "src/components/Container/Container";
import Stack from "@ds/Stack/Stack";

import useIsMobile from "@hooks/useIsMobile";

import "./Hero.scss";

const Hero = () => {
  const isMobile = useIsMobile("md");

  return (
    <Container className="hero" maxWidth="100%" withPadding>
      <Container className="pt-xs-5 pt-md-15">
        <Stack rowGap={isMobile ? 32 : 80} justifyContent="flex-start" alignItems="center" fullWidth>
          <Stack fullWidth alignItems="center" rowGap={16}>
            {isMobile && (
              <div className="logo-holder">
                <img
                  src="/images/bg/rings-blue-green-small.png"
                  className="rings"
                  loading="lazy"
                  width={799}
                  height={799}
                />
                <img src="/images/logos/logo-120.png" className="logo" />
              </div>
            )}
            <p className="caption-medium uppercase white-0 t-xs-c">Unlock Fun and Rewards</p>
            <div className="title-holder">
              <h1 className="title white-0 t-c" style={{ maxWidth: "700px" }}>
                Play and Earn USDT with G2earn
              </h1>
              <img src="/images/bg/rings-blue-green-big.png" className="rings" loading="lazy" />
            </div>
          </Stack>
          <img
            src={`/images/placeholders/placeholder-video${isMobile ? "-mobile" : ""}.avif`}
            className={`video`}
            loading="lazy"
            width={isMobile ? 358 : 1052}
            height={isMobile ? 635 : 592}
          />
        </Stack>
      </Container>
    </Container>
  );
};

export default Hero;
