import Container from "src/components/Container/Container";

import Stack from "@ds/Stack/Stack";
import Group from "@ds/Group/Group";
import Button from "@ds/Button/Button";
import Clickable from "src/components/Clickable/Clickable";

import useIsMobile from "@hooks/useIsMobile";

import { AFFILIATION_PANEL_URL } from "src/config";

import "./Affiliation.scss";

const Affiliation = () => {
  const isMobileSml = useIsMobile("smL");
  const isMobileLg = useIsMobile("lg");
  const fullWidthClass = isMobileSml ? "w-xs-100" : "";

  return (
    <Container className="pt-xs-10 pt-md-20" maxWidth="100%">
      <Container className="affiliation" maxWidth="100%" withPadding>
        <Container className="h-lg-100">
          <Stack rowGap={80} className="off-xs-0 off-lg-4 off-xl-5 off-xxl-6" justifyContent="center" fullSize>
            <Stack fullWidth className="relative">
              {isMobileLg && (
                <img src="/images/affiliation/bg-mobile.avif" className="bg-mobile" height={178} width={586} />
              )}
              <p className="caption-medium white-0 mb-xs-1 mb-md-2">Payment every 24 hours</p>
              <h3 className="title text-gradient-gold-top-down mb-xs-0 mb-md-1">Affiliation +2,5% USDT</h3>
              <p className="white-0 op-06 mb-xs-3 b3" style={{ maxWidth: "260px" }}>
                Discover Earning Opportunities with Crypto Game Affiliate Links
              </p>
              <Group colGap={16} rowGap={isMobileSml ? 8 : 16} className="grid" fullWidth>
                <Button
                  as="a"
                  target="_blank"
                  rel="noreferrer noopener"
                  href={AFFILIATION_PANEL_URL}
                  className={`${fullWidthClass}`}
                >
                  <Clickable
                    variant={"primary"}
                    coloring={"green"}
                    size={isMobileSml ? "small" : "medium"}
                    className={fullWidthClass}
                  >
                    <Group fullSize justifyContent="center" alignItems="center">
                      <p className="butt-3 white-0">Get your affiliation link</p>
                    </Group>
                  </Clickable>
                </Button>
                <Button as="a" href="" className={`${fullWidthClass} disabled`}>
                  <Clickable
                    variant={"secondary"}
                    coloring={"green"}
                    size={isMobileSml ? "small" : "medium"}
                    className={fullWidthClass}
                    isDisabled
                  >
                    <Group fullSize justifyContent="center" alignItems="center">
                      <p className="butt-3 white-0">Learn more</p>
                    </Group>
                  </Clickable>
                </Button>
              </Group>
            </Stack>
            <Stack className="staking" fullWidth>
              <h3 className="text-gradient-purple-top-down title mb-xs-1">Staking up to 4,5% USDT</h3>
              <p className="b3 white-0 op-06 mb-xs-1 mb-md-3">Soon...</p>
              <Group
                className="relative"
                colGap={16}
                rowGap={16}
                fullWidth={isMobileSml}
                justifyContent="flex-start"
                style={{
                  flexDirection: isMobileSml ? "column-reverse" : "row",
                  alignItems: isMobileSml ? "flex-start" : "flex-end"
                }}
              >
                <Button as="a" href="" className={`disabled ${fullWidthClass}`}>
                  <Clickable
                    variant={"primary"}
                    coloring={"green"}
                    size={isMobileSml ? "small" : "medium"}
                    isDisabled
                    className={fullWidthClass}
                  >
                    <Group fullSize justifyContent="center" alignItems="center">
                      <p className="butt-3 white-0">Available soon</p>
                    </Group>
                  </Clickable>
                </Button>
                <img src="/images/graphics/purple-staking-arrow.svg" />
              </Group>
            </Stack>
          </Stack>
        </Container>
      </Container>
    </Container>
  );
};

export default Affiliation;
