import { GamesKeys } from "@interfaces/GamesKeys";
import { GameCardDef, GameCardFields } from "src/components/GameCard/GameCard";

export const GAMES_SECTION_ID_SELECTOR = "games_section";

export const games: Record<GamesKeys | string, GameCardDef> = {
  coinFlipper: {
    img: "/images/games/coin-flipper.webp",
    title: "Coin Flipper",
    description:
      "Flip a coin and test your luck! Wagers range from 10 cents to $5, with a jackpot multiplier of x3. 92% of the pool is dedicated to winnings",
    isDisabled: false,
    video: "/images/games/videos/coin-flipper.avif"
    // video: "/images/games/videos/coin-flipper.mp4"
  },
  luckyStrike: {
    img: "/images/games/lucky-strike.webp",
    title: "Lucky Strike",
    description:
      "Score a goal! This game combines skill and chance. Wagers range from 10 cents to $5, with a jackpot multiplier of x3. 92% of the pool is dedicated to winnings",
    isDisabled: false,
    video: "/images/games/videos/lucky-strike.avif"
    // video: "/images/games/videos/lucky-strike.mp4"
  },
  toTheMoon: {
    img: "/images/games/to-the-moon.webp",
    title: "To the moon",
    description:
      "Bet on the rocket's launch. The goal is deep space. Wagers range from 10 cents to $5. 92% of the pool is dedicated to winnings.",
    isDisabled: false,
    video: "/images/games/videos/to-the-moon.avif"
    // video: "/images/games/videos/to-the-moon.mp4"
  },
  bomber: {
    img: "/images/games/bomber.webp",
    title: "Bomber",
    description:
      "A dynamic skill and luck game: destroy 100% of the targets to get a bonus of x10. 92% of the pool is dedicated to winnings",
    isDisabled: true
  },
  minesweeper: {
    img: "/images/games/minesweeper.webp",
    title: "Minesweeper",
    description:
      "An explosive game of chance and logic. Try your luck and clear the field. Wagers range from 10 cents to $10, with a board bonus of x5. 91.5% of the pool is dedicated to winnings",
    isDisabled: true
  },
  spacePilot: {
    img: "/images/games/space-pilot.webp",
    title: "Space pilot",
    description:
      "The goal? Destroy targets and reach the base to get a bonus of x5. You will earn a reward for each target. Wagers range from 10 cents to $5. 92.5% of the pool is dedicated to winnings",
    isDisabled: true
  },
  sniper: {
    img: "/images/games/sniper.webp",
    title: "Sniper",
    description:
      "The difficulty of opponents increases with the stake. Wagers range from 10 cents to $3 per target, with a bonus of x3. 93% of the pool is dedicated to winnings",
    isDisabled: true
  },
  huner: {
    img: "/images/games/hunter.webp",
    title: "Hunter",
    description:
      "A dynamic game where you prove how good a hunter you are. Bonus for hits is x3. Wagers range from 5 cents to $5. 90% of the pool is dedicated to winnings",
    isDisabled: true
  },
  golf: {
    img: "/images/games/golf.webp",
    title: "Golf",
    description:
      "Wind and distance challenge your skills. Bonus for 5 strokes is x5. Wagers range from 5 cents to $3. 91.5% of the pool is dedicated to winnings",
    isDisabled: true
  },
  rocketMulti: {
    img: "/images/games/rocket.webp",
    title: "Rocket - multi",
    description:
      "Bet on how far the rocket will fly. The stake depends on the pool and the number of players. 95% of the pool is dedicated to winnings",
    isDisabled: true
  },
  duelMulti: {
    img: "/images/games/duel.webp",
    title: "Duel - multi",
    description:
      "Choose your role: goalkeeper or shooter. The stake per shot ranges from $2 to $5. 90% of the pool goes to the winning player, with a 10% platform fee",
    isDisabled: true
  },
  darts: {
    img: "/images/games/darts.webp",
    title: "Darts",
    description:
      "The difficulty of opponents increases with the stake. Wagers range from 10 cents to $3 per win, with a bonus of x3. 93% of the pool is dedicated to winnings",
    isDisabled: true
  },
  cars: {
    img: "/images/games/cars.webp",
    title: "Cars",
    description:
      "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    isDisabled: true
  },
  robotsWar: {
    img: "/images/games/robots-war.webp",
    title: "Robots war",
    description:
      "Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet",
    isDisabled: true
  },
  baseball: {
    img: "/images/games/baseball.webp",
    title: "Baseball",
    description:
      "Win match. The stake depends on the pool and the number of players. 95% of the pool is dedicated to winnings.",
    isDisabled: true
  }
};
