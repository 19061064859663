import Stack from "@ds/Stack/Stack";
import NavBtn from "./NavBtn";
import Corner from "src/components/Corner/Corner";
import Button from "@ds/Button/Button";
import Group from "@ds/Group/Group";

import { useUIControllerCtx } from "src/App/contexts/UIController";

import { affiliationBtn, buyTokenBtn, stakingBtn } from "../constants/buttons";
import { socialsMap } from "src/components/Socials/socialsMap";

const mobileMenuButtonsSet = [affiliationBtn, buyTokenBtn].map((btn, id) => (
  <NavBtn {...btn} className="w-xs-100" key={id} />
));

const MobileMenu = () => {
  const { mobileMenu } = useUIControllerCtx();

  return (
    <div className={`mobile-menu ${mobileMenu.status}`}>
      <Stack justifyContent="flex-end" fullSize>
        <Stack fullWidth rowGap={40}>
          <img src="/images/logos/logo-menu.png" />
          <Stack fullWidth rowGap={8}>
            {mobileMenuButtonsSet}
          </Stack>
          <NavBtn {...stakingBtn} className="w-xs-100" />
          <Group justifyContent="space-around" alignItems="center" fullWidth>
            {socialsMap()}
          </Group>
        </Stack>
      </Stack>
      <Corner top right>
        <Button as="button" onClick={mobileMenu.close} style={{ padding: "8px" }}>
          <img src="/images/icons/x.svg" />
        </Button>
      </Corner>
    </div>
  );
};

export default MobileMenu;
