import { ScreenSizes } from "@interfaces/ScreenSizes";

export const screenSizes: Record<ScreenSizes, number> = {
  xs: 0,
  sm: 360,
  smL: 576,
  md: 768,
  lg: 991,
  xl: 1200,
  xxl: 1400,
  hd: 1920
};
