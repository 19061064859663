import { AppStage } from "@interfaces/AppStage";

export const APP_STAGE =
  (process.env.REACT_APP_STAGE as AppStage) ||
  (() => {
    throw new Error("REACT_APP_STAGE not provided");
  })();

export const AFFILIATION_PANEL_URL = process.env.REACT_APP_AFFILIATION_PANEL_URL || "";

export const GAMES_URLS = JSON.parse(process.env.REACT_APP_GAMES_URLS || "{}");
