import Container from "src/components/Container/Container";
import Stack from "@ds/Stack/Stack";

import useIsMobile from "@hooks/useIsMobile";

import "./Profits.scss";

const Profits = () => {
  const isMobile = useIsMobile("xxl");
  const isMobileLg = useIsMobile("lg");

  const mobileGraphics = [
    "staking-g2e-mobile.svg",
    "refferals-mobile.svg",
    "dex-cex-mobile.svg",
    "win-usdt-mobile.svg"
  ];

  return (
    <Container className="profits" maxWidth="100%" withPadding>
      <Container className="pt-xs-10 pt-md-20">
        <Stack fullWidth alignItems="center">
          <Stack fullWidth alignItems={isMobileLg ? "flex-start" : "center"} rowGap={48}>
            <Stack fullWidth alignItems="center" rowGap={isMobile ? 0 : 16}>
              <h3 className="title">Passive profits: Staking - Referral links</h3>
              <p className="b3 op-06">
                We share the profits from the games. You can receive up to 6% on your bets. Payment is made every 24
                hours.
              </p>
            </Stack>
            {isMobileLg ? (
              <Stack fullWidth rowGap={24} alignItems="flex-start" className="relative">
                {mobileGraphics.map((img, id) => (
                  <img src={`/images/graphics/${img}`} className="img-mobile" key={id} />
                ))}
                <img
                  src="/images/graphics/gaymer-mobile.avif"
                  className="gaymer"
                  loading="lazy"
                  width={309}
                  height={309}
                />
              </Stack>
            ) : (
              <img src="/images/graphics/profits.png" className="img" loading="lazy" height={1360} width={2251} />
            )}
          </Stack>
        </Stack>
      </Container>
    </Container>
  );
};

export default Profits;
