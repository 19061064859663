import React from "react";

import { IChildren } from "@interfaces/IChildren";
import useMobileMenu, { MobileMenuReturn } from "./hooks/useMobileMenu";

interface IUIController {
  mobileMenu: MobileMenuReturn;
}

const UIControllerCtx = React.createContext(null as any);

export const UIController = ({ children }: IChildren) => {
  const mobileMenu = useMobileMenu();

  return <UIControllerCtx.Provider value={{ mobileMenu }}>{children}</UIControllerCtx.Provider>;
};

export const useUIControllerCtx = (): IUIController => React.useContext(UIControllerCtx);
