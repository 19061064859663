import { TChildren } from "@interfaces/IChildren";
import { BaseProps } from "@interfaces/BaseProps";
import { Coloring } from "@interfaces/Coloring";

import "./Clickable.scss";

export type BtnVariant = "primary" | "secondary" | "tertiary";
export type BtnSize = "small" | "medium" | "big";

interface Config extends BaseProps {
  variant: BtnVariant;
  coloring: Coloring;
  size: BtnSize;
  isDisabled?: boolean;
  children: TChildren;
  byPass?: boolean;
}

const Clickable = (config: Config) => {
  if (config.byPass) return <>{config.children}</>;

  return (
    <div
      className={`clickable size-${config.size} variant-${config.variant} ${config.isDisabled ? "disabled" : ""} ${
        config.className
      }`}
      style={{
        ...config.style,
        ["--clickable-color" as any]: `var(--${config.coloring})`,
        ["--clickable-color-darker" as any]: `var(--${config.coloring}-darker)`,
        ["--colorKey" as any]: `var(--${config.coloring}10)`
      }}
    >
      {config.children}
    </div>
  );
};

export default Clickable;
