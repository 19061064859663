import { BaseProps } from "@interfaces/BaseProps";
import "./Carousel.scss";

interface CarouselProps extends BaseProps {}

const Carousel = ({ children, className }: CarouselProps) => {
  return (
    <div className={`carousel-wrapper ${className}`}>
      <div className="carousel">{children}</div>
    </div>
  );
};

export default Carousel;
