// import { Outlet } from "react-router";
import { Helmet, HelmetProvider } from "react-helmet-async";

import i18n from "src/translations/i18n";
import { PATHS } from "../constants/paths";

import Navbar from "./sections/Navbar/Navbar";
import Hero from "./sections/Hero/Hero";
import Games from "./sections/Games/Games";
import Affiliation from "./sections/Affiliation/Affiliation";
import HowToUse from "./sections/HowToUse/HowToUse";
import Profits from "./sections/Profits/Profits";
import Numbers from "./sections/Numbers/Numbers";
import Links from "./sections/Links/Links";
import Footer from "./sections/Footer/Footer";
import LightPaper from "./sections/LightPaper/LightPaper";

import { UIController } from "./contexts/UIController";

import "@scss/reset.scss";
import "@scss/grid.scss";
import "@scss/utils.scss";
import "@scss/typography.scss";
import "@scss/layers.scss";
import "@scss/responsive.scss";
import "@scss/root.scss";

const App = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: i18n.language }} />
      <Navbar />
      <Hero />
      {/* <Numbers /> */}
      <Games />
      <Profits />
      <Affiliation />
      <HowToUse />
      <LightPaper />
      <Links />
      <Footer />
    </>
  );
};

export default {
  path: PATHS.app,
  element: (
    <HelmetProvider>
      <UIController>
        <App />
      </UIController>
    </HelmetProvider>
  ),
  children: []
};
