import Group from "@ds/Group/Group";
import { INavBtn } from "../Navbar";
import { openNewTab } from "@utils/utils";
import { AFFILIATION_PANEL_URL } from "src/config";

export const stakingBtn: INavBtn = {
  isDisabled: true,
  content: (
    <Group fullSize alignItems="center" justifyContent="center" colGap={4}>
      <p className="butt-3 white-0">Staking up to</p>
      <p className="butt-3 green">+4.5% USDT</p>
    </Group>
  ),
  action: () => console.log("staking"),
  coloring: "green",
  variant: "secondary"
};

export const affiliationBtn: INavBtn = {
  isDisabled: false,
  content: (
    <Group fullSize alignItems="center" justifyContent="center" colGap={4}>
      <p className="butt-3 white-0">Affiliation link</p>
      <p className="butt-3 green">+2.5% USDT</p>
    </Group>
  ),
  action: () => openNewTab(AFFILIATION_PANEL_URL),
  coloring: "green",
  variant: "secondary"
};

export const buyTokenBtn: INavBtn = {
  isDisabled: true,
  content: (
    <Group fullSize alignItems="center" justifyContent="center" colGap={8}>
      <p className="butt-3 white-0">Buy</p>
      <img src="/images/icons/uniswap-white-40.svg" />
    </Group>
  ),
  action: () => console.log("buy"),
  coloring: "pink",
  variant: "primary"
};
