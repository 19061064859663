import Container from "src/components/Container/Container";

import Stack from "@ds/Stack/Stack";
import { TERMS_AND_CONDITIONS } from "@constants/constants";

import useIsMobile from "@hooks/useIsMobile";
import Socials from "src/components/Socials/Socials";

import "./Footer.scss";

const Footer = () => {
  const isMobile = useIsMobile("smL");

  return (
    <Container className="footer" maxWidth="100%" withPadding>
      <Container className="pt-xs-15 relative" style={{ paddingBottom: "150px" }}>
        <Stack fullWidth rowGap={80} justifyContent="space-betweeen" alignItems="center">
          <Socials isMobile={isMobile} />
          <a href={TERMS_AND_CONDITIONS} className="white-0 op-06 underline">
            Terms and Conditions
          </a>
        </Stack>
        <img src="/images/bg/ring-gold-medium.png" className="ring" loading="lazy" />
      </Container>
    </Container>
  );
};

export default Footer;
