export const socials = [
  {
    icon: "/images/socials/telegram-white-60.svg",
    url: "https://t.me/G2EarnOfficial"
  },
  {
    icon: "/images/socials/twitter-white-60.svg",
    url: "https://x.com/g2earn"
  },
  {
    icon: "/images/socials/yt-white-60.svg",
    url: "https://www.youtube.com/@g2earn"
  }
];
