import { Coloring } from "@interfaces/Coloring";
import { TChildren } from "@interfaces/IChildren";
import { BaseProps } from "@interfaces/BaseProps";

import "./LinkLike.scss";

interface Props extends BaseProps {
  size: "medium" | "small";
  isDisabled: boolean;
  coloring: Coloring;
  children: TChildren;
}

const LinkLike = ({ size, coloring, isDisabled, children, ...rest }: Props) => {
  return (
    <div
      className={`link-like size-${size} ${isDisabled ? "disabled" : ""} ${rest.className}`}
      style={{
        ["--link-color" as any]: `var(--${coloring})`,
        ...rest.style
      }}
    >
      {children}
    </div>
  );
};

export default LinkLike;
