import { BaseProps } from "@interfaces/BaseProps";
import { layoutConsistency } from "@constants/layoutConsistency";

interface Props extends BaseProps {
  withPadding?: boolean;
  maxWidth?: string;
}

const Container = ({ maxWidth = `${layoutConsistency.maxAppWidth}px`, ...props }: Props) => {
  const { withPadding, ...restProps } = props;
  return (
    <div
      {...restProps}
      className={`container ${props.className}`}
      style={{
        ...props.style,
        maxWidth,
        margin: "0px auto",
        paddingLeft: props.withPadding ? "16px" : undefined,
        paddingRight: props.withPadding ? "16px" : undefined
      }}
    >
      {props.children}
    </div>
  );
};

export default Container;
