import { screenSizes } from "src/constants/screenSizes";
import { useWindowDimensions } from "./useWindowDimensions";
import { ScreenSizes } from "@interfaces/ScreenSizes";

const useIsMobile = (maxScreenSize: ScreenSizes) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= screenSizes[maxScreenSize];

  return isMobile;
};

export default useIsMobile;
