import Group from "@ds/Group/Group";
import { socialsMap } from "./socialsMap";

const Socials = ({ isMobile }: { isMobile: boolean }) => {
  return (
    <Group
      justifyContent={`${isMobile ? "space-around" : "center"}`}
      colGap={isMobile ? 0 : 80}
      alignItems="center"
      fullWidth
    >
      {socialsMap()}
    </Group>
  );
};

export default Socials;
