import Container from "src/components/Container/Container";

import Stack from "@ds/Stack/Stack";
import Group from "@ds/Group/Group";
import Button from "@ds/Button/Button";
import Clickable from "src/components/Clickable/Clickable";
import ShadowBox from "src/components/ShadowBox/ShadowBox";

import useIsMobile from "@hooks/useIsMobile";

import "./HowToUse.scss";
import { scrollToView } from "@utils/utils";
import { GAMES_SECTION_ID_SELECTOR } from "@constants/games";

const cardsContent = [
  {
    img: "/images/icons/trophy-white-28.svg",
    title: "games",
    bullets: [
      "select a game,",
      "connect MetaMask,",
      "deposit USDT,",
      "have fun,",
      "withdraw whenever you want. USDT to the connected wallet. If you do not withdraw the funds after the game, the withdrawal will automatically return after 24 hours."
    ]
  },
  {
    img: "/images/icons/chart-white-28.svg",
    title: "referral link",
    bullets: [
      "connect MetaMask,",
      "get a referral link (you will receive a link connected to your wallet where USDT from games will be deposited),",
      "distribute the referral link to earn. Withdrawal is automatic every 24 hours or upon your request."
    ]
  },
  {
    img: "/images/icons/cash-white-28.svg",
    title: "staking",
    bullets: [
      "purchase G2Earn units on CEX/DEX,",
      "stake the units on the platform,",
      "we pay out staking to your account in USDT from games every 24 hours."
    ]
  }
];

const HowToUse = () => {
  const isMobile = useIsMobile("lg");

  const fullWidthClass = `${isMobile ? "w-xs-100" : ""}`;

  return (
    <Container className="how-to-use" maxWidth="100%" withPadding>
      <Container className="pt-xs-10">
        <Stack rowGap={80} fullSize alignItems="center">
          <Stack fullWidth alignItems="center">
            <p className="white-0 caption-medium mb-xs-1 mb-md-2 w-xs-100 t-xs-c">Unlock Fun and Rewards</p>
            <h3 className="title white-0 mb-xs-3 w-xs-100 t-xs-c">How to Use G2Earn</h3>
            {/* <p className="b3 white-0 op-06 mb-xs-4 w-xs-100 t-xs-c">
              Discover the exciting world of crypto gaming and start earning today.
            </p> */}
            <Group
              justifyContent="center"
              alignItems="flex-start"
              colGap={20}
              rowGap={isMobile ? 8 : 20}
              fullWidth
              className="grid mb-xs-3 mb-md-5 relative"
            >
              <img src="/images/bg/rings-blue-gold-medium.png" className="rings" loading="lazy" />
              {cardsContent.map((set, id) => (
                <ShadowBox style={{ maxWidth: "358px", minHeight: isMobile ? "unset" : "358px" }} key={id}>
                  <Group
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    colGap={16}
                    key={id}
                    style={{ width: "unset" }}
                    // style={{ maxWidth: "100%" }}
                  >
                    <img src={set.img} />
                    <Stack justifyContent="flex-start" alignItems="flex-start">
                      <Group justifyContent="center" alignItems="center" className="mb-xs-1" style={{ height: 28 }}>
                        <p className="caption-medium uppercase">{set.title}</p>
                      </Group>
                      <ol style={{ maxWidth: "280px" }}>
                        {set.bullets.map((bullet, bId) => (
                          <li style={{ listStyleType: "disc" }} key={bId}>
                            <p className="b3 white-0 op-06">{bullet}</p>
                          </li>
                        ))}
                      </ol>
                    </Stack>
                  </Group>
                </ShadowBox>
              ))}
            </Group>
            <Group fullWidth justifyContent="center" colGap={16} rowGap={isMobile ? 8 : 16} className="grid">
              <Button
                as="button"
                disabled={true}
                onClick={() => console.log("connect metamask")}
                className={fullWidthClass}
              >
                <Clickable
                  variant={"primary"}
                  coloring={"green"}
                  size={isMobile ? "small" : "medium"}
                  isDisabled={true}
                  className={fullWidthClass}
                >
                  <Group fullSize justifyContent="center" alignItems="center">
                    <p className="butt-3 white-0">Connect wallet</p>
                  </Group>
                </Clickable>
              </Button>
              <Button as="button" className={fullWidthClass} onClick={() => scrollToView(GAMES_SECTION_ID_SELECTOR)}>
                <Clickable
                  variant={"secondary"}
                  coloring={"green"}
                  size={isMobile ? "small" : "medium"}
                  className={fullWidthClass}
                >
                  <Group fullSize justifyContent="center" alignItems="center">
                    <p className="butt-3 white-0">Play now!</p>
                  </Group>
                </Clickable>
              </Button>
            </Group>
          </Stack>
        </Stack>
      </Container>
    </Container>
  );
};

export default HowToUse;
