export const TERMS_AND_CONDITIONS = "";

export const GO_TO_METAMASK_URL = `https://metamask.app.link/dapp/${window.location.host}`;

export const GLOBAL_TIMEOUT = 8000; //[ms]

export const REGULATIONS_PATH = "";

export const GOOGLE_URL = "https://google.com";

export const ARBISCAN_CONTRACT_ADDRESS = "Contract available soon";

export const HOW_TO_PLAY_ON_MOBILE = "https://www.youtube.com/watch?v=SSZ7Aw6oWrw";

export const HOW_TO_PLAY_ON_DESKTOP = "https://www.youtube.com/watch?v=DkqL8DM8sNM";

export const GITBOOK_LIGHTPAPER = "";
